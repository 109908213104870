import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import LocomotiveScroll from 'locomotive-scroll'

let scroller

$(window).resize(function(){
  init()
  if($(window).width() > 1024){
    $('.section3').height($('.section3 .fixed-cont').outerWidth() - $(window).width() + $(window).height());
  }else{
    $('.section3').css('height','auto');
  }
})

$(document).ready(function(){
  init()
})

function init(){
  scroller = new LocomotiveScroll({});
}

//banner轮播
var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper(".section1 .mySwiper", {
  speed: 800,
  watchSlidesProgress: true,
  // loop: true,
  // autoplay: {
  //   delay: 6000,
  //   stopOnLastSlide: false,
  //   disableOnInteraction: false,
  //   waitForTransition: false,
  // },
  on:{
    progress: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			var slideProgress = swiper.slides[i].progress;
			var innerOffset = swiper.width * interleaveOffset;
			var innerTranslate = slideProgress * innerOffset;
			swiper.slides[i].querySelector(".slide-inner").style.transform =
			  "translate3d(" + innerTranslate + "px, 0, 0)";
		  }      
		},
		touchStart: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = "";
		  }
		},
		setTransition: function(swiper, speed) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = speed + "ms";
			swiper.slides[i].querySelector(".slide-inner").style.transition =
			  speed + "ms";
		  }
		}
  },
})

var bannerswiper2 = new Swiper(".section4 .mySwiper", {
  speed: 800,
  watchSlidesProgress: true,
  loop: true,
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  navigation: {//按钮
    nextEl: ".section4 .swiper-button-next",
    prevEl: ".section4 .swiper-button-prev",
  },
  pagination: {// 分页
    el: ".section4 .swiper-pagination",
    clickable: true,
  },
  on:{
    progress: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			var slideProgress = swiper.slides[i].progress;
			var innerOffset = swiper.width * interleaveOffset;
			var innerTranslate = slideProgress * innerOffset;
			swiper.slides[i].querySelector(".slide-inner").style.transform =
			  "translate3d(" + innerTranslate + "px, 0, 0)";
		  }      
		},
		touchStart: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = "";
		  }
		},
		setTransition: function(swiper, speed) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = speed + "ms";
			swiper.slides[i].querySelector(".slide-inner").style.transition =
			  speed + "ms";
		  }
		}
  },
})

// 查看更多
var clickNum = 0
var initshowliNum = 6
var showstep = 6
var totalli = $('.section5 .pro-list li').length

if (totalli <= initshowliNum) {
    $('.section5 .more').hide()
}

$('.section5 .more a').on('click', function () {
    clickNum += 1
    if (initshowliNum + showstep * clickNum < totalli) {
        for (let i = 0; i < showstep; i++) {
            $('.section5 .pro-list li').eq(initshowliNum + (clickNum - 1) * showstep + i).show()
        }
    } else {
        for (let i = 0; i < totalli - clickNum * showstep; i++) {
            $('.section5 .pro-list li').eq(initshowliNum + (clickNum - 1) * showstep + i).show()
        }
        $('.section5 .more').hide()
    }
})

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度
  let proH = $('.section3').outerHeight()
  let proTop = $('.section3').offset().top
  let proBtm = $('.section4').offset().top

  let proH2 = $('.section5').outerHeight()
  let proTop2 = $('.section5').offset().top
  let proBtm2 = $('footer').offset().top
  
  if($(window).width() > 1024){
    // 设置section3的高度
    $('.section3').height($('.section3 .fixed-cont').outerWidth() - $(window).width() + viewH);

    // 设置section3的内容浮动
    if(scroH > proTop && scroH < proBtm - viewH){
      $('.section3 .fixed-cont').addClass('fixed').removeClass('abs')
      // 设置section3的内容横向滚动
      $('.section3 .fixed-cont').css('transform', 'translateX(-' + (scroH - proTop) + 'px)')
      $('.section3 .fixed-cont .bg').css('transform', 'translateX(' + (scroH - proTop) + 'px)')
      // $('.section3 .fixed-cont .sec3-list .item-img b').css('transform', 'translateX(' + -(scroH - proTop)*0.2 + 'px)')
    }else if(scroH >= proBtm - viewH){
      $('.section3 .fixed-cont').removeClass('fixed').addClass('abs')
    }else{
      $('.section3 .fixed-cont').removeClass('fixed').removeClass('abs')
    }

    if(scroH > proTop2){
      $('.scroll-icon').fadeOut()
    }else{
      $('.scroll-icon').fadeIn()
    }
  }else{
    if(proH > viewH){
      $('.section3 .fixed-cont .bg').css('height','100vh')
      if(scroH > proTop && scroH < proBtm - viewH){
        $('.section3 .fixed-cont .bg').addClass('fixed').removeClass('abs')
      }else if(scroH >= proBtm - viewH){
        $('.section3 .fixed-cont .bg').removeClass('fixed').addClass('abs')
      }else{
        $('.section3 .fixed-cont .bg').removeClass('fixed').removeClass('abs')
      }
    }else{
      $('.section3 .fixed-cont .bg').css('height','100%')
    }
  }

  if(proH2 > viewH){
    $('.section5 .back-bg').css('height','100vh')
    if(scroH > proTop2 && scroH < proBtm2 - viewH){
      $('.section5 .back-bg').addClass('fixed').removeClass('abs')
    }else if(scroH >= proBtm2 - viewH){
      $('.section5 .back-bg').removeClass('fixed').addClass('abs')
    }else{
      $('.section5 .back-bg').removeClass('fixed').removeClass('abs')
    }
  }else{
    $('.section5 .back-bg').css('height','100%')
  } 
});
